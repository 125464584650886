.input-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
  position: relative;
  left: 18rem;
  top: -5.5rem;
}

.field-groupe {
  width: 600px;
  position: relative;
  padding-top: 15px;
}
.input-field {
  width: 100%;
  outline: 0;
  border: none;
  border-bottom: 1.5px solid #fff;
  padding: 10px 0;
  background: transparent;
  font-size: 1.5rem;
  color: #fff;
  transition: 0.3s ease;
}
.input-label {
  color: #fff;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  display: block;
  position: absolute;
  top: 0;
  color: #999999;
  transition: 0.3s ease;
}

.input-field::placeholder {
  color: transparent;
}
.input-field::placeholder ~ shown .input-label {
  font-size: 1.5rem;
  top: 20px;
}
.input-field:focus {
  border-image: linear-gradient(to right, #0071b8, #24c8ff);
  border-image-slice: 1;
}
.input-field:focus ~ .input-label {
  display: block;
  position: absolute;
  top: 0;
  font-size: 1rem;
  color: #24c8ff;
}

.contactbox {
  color: #fff;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  height: 22.125rem;
  padding: 10px 10px;
  letter-spacing: 0.015rem;
  background: transparent;
  border: 1.5px solid #fff;
  outline: 0;
  transition: 0.3s ease;
}
.contactbox::placeholder {
  color: #999999;
}
.contactbox:focus {
  border-image: linear-gradient(to right, #0071b8, #24c8ff);
  border-image-slice: 1;
}

.Envoyer {
  color: white;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  width: 13.4375rem;
  height: 3.9375rem;
  flex-shrink: 0;
  align-self: right;
  border-radius: 2.84375rem;
  border: 1px solid #f9fdff;
  background-color: transparent;
  position: relative;
  right: -24rem;

  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.Envoyer:hover {
  border: 1px solid #24c8ff;
  color: #24c8ff;
}
