#section3 {
  height: 56.6875rem;
  display: flex;
  flex-direction: column;
}
.headers3 {
  width: 69.875rem;
  color: #5c9afe;
  text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 4.8rem */
  letter-spacing: 0.03rem;
  position: relative;
  
}
.pargraphs3 {
  width: 48.25rem;
  height: 5.0625rem;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 1.9125rem */
  letter-spacing: 0.01125rem;
  margin-top:0rem;
  margin-bottom: 4rem;
}
#cards {
  width: 86.6875rem;
  height: 30.4375rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  padding: 20px;
  justify-content: space-between;
}
.box {
  width: 24.3125rem;
  height: 21.8125rem;
  flex-shrink: 0;
  border-radius: 3rem;
  background: #fff;
  color: #000;
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.10);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition-property: background-color, transform;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
.box:hover {
  background: linear-gradient(to bottom right, #5c9afe, #d4c2f2);
  color: #fff;
  transform: scale(1.1);
}
.cardh {
  width: 26.0625rem;
  height: 6.625rem;
  flex-shrink: 0;
  text-align: center;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 2.6rem */
  letter-spacing: 0.02rem;
  text-transform: capitalize;
  position: relative;
  top:2rem;
}
.cardp {
  width: 21.9375rem;
  height: 8.9375rem;
  flex-shrink: 0;
  text-align: center;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
