
  .header {
  z-index: 999;
  margin-top: 3vw;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  transition: 0.3s ease-in-out;
  position: fixed;
}

#navbar {
  width: 76.6875rem;
  height: 6.5rem;
  flex-shrink: 0;
  border-radius: 3.25rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.02) 0%,
    rgba(255, 255, 255, 0.01) 100%
  );
  box-shadow: -44.36666488647461px 44.36666488647461px 44.36666488647461px 0px
      rgba(255, 255, 255, 0.04) inset,
    44.36666488647461px -44.36666488647461px 44.36666488647461px 0px rgba(
        194,
        194,
        194,
        0.04
      ) inset;
  backdrop-filter: blur(16.415666580200195px);
  justify-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  left: -1rem;
}

.logosvg {
  width: 13rem;
  height: 9.75rem;
  flex-shrink: 0;
  background: transparent url(../../assets/logo.svg) center / contain no-repeat;
  position: relative;
  top: -1.5rem;
}

.logo img {
  height: 40px;
}
.navb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  position: relative;
  left: -4rem;
}

.btn-txt {
  color: #59CFE9;
  font-family: Poppins;
  font-size: 1.02rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.btn-txt:hover {
  color: #e8e8e8;
  transform: scale(1.1);
}


