 /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 431px) and (max-width: 769px) {

  /*section1*/
  #section1 {
    height: 36rem;
    background: url(../src/assets/homeb.png);
    background-size: cover;
    background-position: center;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .logo{
    width: 10rem;
    height: 10.375rem;
    background: url(../src/assets/logo.svg)center / contain no-repeat;
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;
    position: relative;
    right: 19rem;
    bottom:6rem;
    margin-bottom: 0%;
  }
  #navbar{
    display: none;
  }
  .main-txt {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    font-family: Poppins;
    font-size: 1.95rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 1rem;
    margin-top: 0%;
    position: relative;
    bottom: 3.5rem;
  }
  
  .Homepargraph {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1rem;
    text-transform: capitalize;
    width: 33rem;
    height: 2.375rem;
    flex-shrink: 0;
    margin-top: 0rem;
    position: relative;
    bottom: 3.5rem;
  }
  
  .Discoverb {
    color: #FFF;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    width: 7.25rem;
    height: 2.3125rem;
    flex-shrink: 0;
    border-radius: 2.84375rem;
    border: 1px solid #FFF;
    background-color: transparent;
    color: white;
    margin: 0px;
    margin-bottom: 10%;
    position: relative;
    bottom: 3rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  .Discoverb:hover {
    background-color: #ffffff;
    color: #000000;
  }
  /*section2*/
  #section2 {
    height:36rem;
    display: flex;
    flex-direction: column;
  }
  .nosservices {
    width: 11.125rem;
    height: 2.5rem;
    flex-shrink: 0;
    margin-right: 0%;
    background: transparent url(../src/assets/nos_services.png) center / contain no-repeat;
    position: relative;
    align-self: center;
    top: 3rem;
  }
  
  .headers2 {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 2.2rem */
    letter-spacing: 0.01375rem;
    width: 47rem;
    position: relative;
    top: 5rem;
    margin: 0%;
  }
  .pargraphs2 {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 1.275rem */
    letter-spacing: 0.0075rem;
    width: 47rem;
    position: relative;
    top: 5.4rem;
    margin: 0%;
    margin-bottom: 4%;
  }
  .man1png {
    width: 135vw;
    height: 80rem;
    background: transparent url(../src/assets/man1.png) center / contain no-repeat;
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;
    margin-top: 0%;
    margin-bottom: 0%;
    padding: 0px;
    position: relative;
    top:5rem;
    left:0%;
  }
  /*section3*/
  #section3 {
    height: 60rem;
    display: flex;
    flex-direction: column;
  }
  .headers3 {
    width: 47rem;
    color: #5c9afe;
    text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
    font-family: Poppins;
    text-align: center;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 4.8rem */
    letter-spacing: 0.03rem;
    position: relative;
    top:2.3rem;
  }
  .pargraphs3 {
    width: 27rem;
    height: 5.0625rem;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 1.9125rem */
    letter-spacing: 0.01125rem;
    margin-top:0rem;
    position: relative;
    top:2.2rem;
  }
  #cards {
    width: 24.875rem;
    height: 41.5625rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    padding: 20px;
    justify-content: space-between;
    margin: 0%;
    position: relative;
    bottom: 4rem;
  }
  .box {
    width: 23.3125rem;
    height: 10.9375rem;
    flex-shrink: 0;
    border-radius: 3rem;
    background: #fff;
    color: #000;
    box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.10);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 0%;
    margin-left: 2.7%;
    cursor: pointer;
    transition-property: background-color, transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
  }
  .box:hover {
    background: linear-gradient(to bottom right, #5c9afe, #d4c2f2);
    color: #fff;
    transform: scale(1.11);
  }
  .cardh {
    width: 26.0625rem;
    height: 6.625rem;
    flex-shrink: 0;
    text-align: center;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 2.6rem */
    letter-spacing: 0.02rem;
    text-transform: capitalize;
    position: relative;
    margin-top: 0%;
    top:3.5rem;
  
  }
  .cardp {
    width: 18.8125rem;
    height: 3.875rem;
    flex-shrink: 0;
    text-align: center;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    bottom: 1.5rem;
    margin-top: 0%;
  }
  /*section4*/
  #section4 {
    height: 40rem;
    display: flex;
    flex-direction: column;
    background-color:#00060C;
    
  }
  
  .man2png {
    width: 100vw;
    height: 30rem;
    flex-shrink: 0;
    background: transparent url(../src/assets/man2.png) center / contain no-repeat;
    position: relative;
    bottom: 16rem;
    margin-top: 20%;
    
  }
  #rectangle {
    width: 35rem;
    height: 10rem;
    flex-shrink: 0;
    border-radius: 9.5rem;
    background: rgba(92, 154, 254, 0.55);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: 0.5rem;
    z-index: 9;
  }
  #header1 {
    color: #fff;
    font-family: Poppins;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 4rem */
    letter-spacing: 0.025rem;
    text-transform: uppercase;
    text-align: center;
    width: 30rem;
    position: relative;
    top: 2.9rem;
    align-self: center;
    margin:0%;
    padding: 0%;
  }
  #header2 {
    color: #fff;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    width: 30rem;
    position: relative;
    top: 2rem;
    margin-bottom: 0%;
    margin-top: 1rem;
  }
  #apropos {
    width:99vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0%;
  }
  #header6 {
    color: #5c9afe;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 4rem */
    letter-spacing: 0.025rem;
    text-transform: uppercase;
    height: 12.25rem;
    flex-shrink: 0;
    flex: 1;
    padding: 30px;
    position: relative;
    top: 1.5rem;
    align-self: center;
  }
  .colored-text {
    color: #ffffff;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.025rem;
    text-transform: uppercase;
    margin-bottom: 0%;
    align-self: center;
  }
  #aproposp {
    color: #FFF;
    font-family: Poppins;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    line-height: 194%; /* 2.1825rem */
    text-transform: capitalize;
    width: 35rem;
    align-self: center;
    flex-shrink: 0;
    position: relative;
    bottom: 20rem;
    top: 0rem;
    flex: 2;
    padding: 0px;
    margin-bottom: 9%;
  }
  .section-a-props {
    width: 14.9375rem;
    margin: 0px;
    padding: 0px;
    min-height: 916px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10%;
    margin-bottom: 0%;
    position: relative;
    top:14rem;
  }
  /*section6*/
  #section6 {
    height: 40rem;
    background: url(../src/assets/DSIbackground.jpg);
    background-size: cover;
    background-position: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Header6 {
    width: 28rem;
    height: 4rem;
    flex-shrink: 0;
    color: #fff;
    font-family: Poppins;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    line-height: 160%; /* 4.8rem */
    letter-spacing: 0.03rem;
    position: relative;
    top: 0rem;
    padding: 0%;
    margin: 0%;
    margin-bottom: 2%;
  }
  .input-data {
    width: 24rem;
    height: 24.6875rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    align-self: center;
    align-items: center;
    position: relative;
    top:0rem;
    left:0rem;
    padding: 0%;
  }
  
  .field-groupe {
    width: 600px;
    position: relative;
    padding-top: 15px;
    align-content: left;
    
  }
  .input-field {
    width: 30rem;
    height: 2.4rem;;
    outline: 0;
    border: none;
    border-bottom: 1.5px solid #fff;
    padding: 0%;
    background: transparent;
    font-size: 0.7rem;
    color: #fff;
    transition: 0.3s ease;
    align-items: left;
    position: relative;
    top:0rem;
  }
  .input-label {
    color: #fff;
    font-family: Poppins;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 400;
    display: block;
    position: absolute;
    bottom: 4rem;
    right: 28.8rem;
    color: #999999;
    transition: 0.3s ease;
    
  }
  
  .input-field::placeholder {
    color: transparent;
  }
  .input-field::placeholder ~ shown .input-label {
    font-size: 0.7rem;
    top: 20px;
  }
  .input-field:focus {
    border-image: linear-gradient(to right, #0071b8, #24c8ff);
    border-image-slice: 1;
  }
  .input-field:focus ~ .input-label {
    display: block;
    position: absolute;
    top: 0;
    font-size: 0.7rem;
    color: #24c8ff;
  }
  
  .contactbox {
    color: #fff;
    font-family: Poppins;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 400;
    width: 29rem;
    height: 10.93144rem;
    padding: 10px 10px;
    letter-spacing: 0.015rem;
    background: transparent;
    border: 1.5px solid #fff;
    outline: 0;
    transition: 0.3s ease;
  }
  .contactbox::placeholder {
    color: #999999;
  }
  .contactbox:focus {
    border-image: linear-gradient(to right, #0071b8, #24c8ff);
    border-image-slice: 1;
  }
  
  .Envoyer {
    color: white;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    width: 7.25rem;
    height: 2.3125rem;
    flex-shrink: 0;
    align-self: right;
    border-radius: 2.84375rem;
    border: 1px solid #f9fdff;
    background-color: transparent;
    position: relative;
    left: 48%;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  .Envoyer:hover {
    border: 1px solid #24c8ff;
    color: #24c8ff;
  }
  /*section7*/
  #section7 {
    height: 14.25rem;
    display: flex;
    flex-direction: column;
    
  }
  
  .copyrightsvg {
    width: 23rem;
    height: 7rem;
    flex-shrink: 0;
    background: transparent url(../src/assets/copyright.svg) center / contain no-repeat;
    position: relative;
    top: 8.1rem;
    left: 2rem;
    padding: 0%;
    margin: 0%;
  }
  #footerc {
    width: 10rem;
    height:15rem;
    display: flex;
    flex-direction: row;
    position: relative;
    left: 27rem;
    top: -3.8rem;
  }
  
  #icons {
    width: 5rem;
    height:7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0rem;
    margin: 0%;
    padding: 0%;
  }
  
  .callsvg {
    width: 1.2rem;
    height: 1.2rem;
    flex-shrink: 0;
    background: transparent url(../src/assets/call.svg) center / contain no-repeat;
    margin: 0%;
  }
  
  .smssvg {
    width: 1.2rem;
    height: 1.2rem;
    flex-shrink: 0;
    background: transparent url(../src/assets/sms.svg) center / contain no-repeat;
    margin: 0%;
  }
  
  .locationsvg {
    width: 1.2rem;
    height: 1.2rem;
    flex-shrink: 0;
    background: transparent url(../src/assets/location.svg) center / contain no-repeat;
    margin: 0%;
  }
  
  #contactf {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:1.3rem;
    width: 15rem;
    height: 7rem;
    position: relative;
    bottom: 0.7rem;
  }
  .contactf {
    color: #151414;
    font-family: Poppins;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 420;
    line-height: 160%; /* 2rem */
    letter-spacing: 0.0125rem;
    text-transform: lowercase;
    margin: 0%;
    padding: 0%;
  }
  
    }
    