#section7 {
  height: 14.25rem;
  display: flex;
  flex-direction: row;
}
.copyrightsvg {
  width: 40rem;
  height: 5rem;
  flex-shrink: 0;
  background: transparent url(../../assets/copyright.svg) center / contain no-repeat;
}
.copyrightsvg {
  width: 40rem;
  height: 5rem;
  flex-shrink: 0;
  background: transparent url(../../assets/copyright.svg) center / contain no-repeat;
  position: relative;
  top: 8.1rem;
  left: 2rem;
}
#footerc {
  width: 26.1875rem;
  height: 9.3125rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  top: 1rem;
  left: 15rem;
}

#icons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap:1rem;
  padding: 14px;
  position: relative;
  top: 0.6rem;
}

.callsvg {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  background: transparent url(../../assets/call.svg) center / contain no-repeat;
}

.smssvg {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  background: transparent url(../../assets/sms.svg) center / contain no-repeat;
}

.locationsvg {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  background: transparent url(../../assets/location.svg) center / contain no-repeat;
}

#contactf {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.2rem;
  padding: 17px;
}
.contactf {
  color: #151414;
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 2rem */
  letter-spacing: 0.0125rem;
  text-transform: lowercase;
  margin:0%;
  position: relative;
  top: 0.25rem;
}
