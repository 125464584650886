#section2 {
  height: 68rem;
  display: flex;
  flex-direction: column;
}
.nosservices {
  width: 20.125rem;
  height: 3.875rem;
  flex-shrink: 0;
  margin-right: 0%;
  background: transparent url(../../assets/nos_services.png) center / contain no-repeat;
  position: relative;
  align-self: center;
  top: 7rem;
}

.headers2 {
  color: #f9fdff;
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 4.8rem */
  letter-spacing: 0.03rem;
  width: 40rem;
  position: relative;
  top: 10rem;
  margin-right: 40%;
}
.pargraphs2 {
  color: #fff;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 2.125rem */
  letter-spacing: 0.03rem;
  width: 31.25rem;
  position: relative;
  top: 8rem;
  margin-right: 50%;
}
.man1png {
  width: 99vw;
  height: 70rem;
  background: transparent url(../../assets/man1.png) center /contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
  object-fit: fill;
  position: relative;
  bottom: 10rem;
  left:13rem;
  
}
