@import "phone.css";
@import "tablet.css";
html,body{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    
}
.section {
    min-height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
  } 