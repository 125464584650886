#section4 {
  height: 70rem;
  display: flex;
  flex-direction: column;
  background-color:#00060C;
}

.man2png {
  width: 99vw;
  height: 55.3125rem;
  flex-shrink: 0;
  background: transparent url(../../assets/man2.png) center / contain no-repeat;
  position: relative;
  margin-top: 0%;
  margin-bottom: 35%;
}
#rectangle {
  width: 67.5625rem;
  height: 19rem;
  flex-shrink: 0;
  border-radius: 9.5rem;
  background: rgba(92, 154, 254, 0.55);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
  display: flex;
  flex-direction: column;
  position: relative;
  top: 8rem;
  z-index: 9;
}
#header1 {
  color: #fff;
  font-family: Poppins;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 4rem */
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  top: 3rem;
}
#header2 {
  color: #fff;
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0%;
}
#apropos {
  width:99vw;
  display: flex;
  flex-direction: row;
  margin-top: 9rem;
  margin-bottom: 0%;
}
#header6 {
  color: #5c9afe;
  font-family: Poppins;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 4rem */
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  height: 12.25rem;
  flex-shrink: 0;
  flex: 1;
  padding: 30px;
  margin-bottom: 0%;
}
.colored-text {
  color: #ffffff;
  font-family: Poppins;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  margin-bottom: 0%;
}
#aproposp {
  color: #FFF;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 194%; /* 2.1825rem */
  text-transform: capitalize;
  height: 21.25rem;
  flex-shrink: 0;
  position: relative;
  top: 0.8rem;
  flex: 2;
  padding: 30px;
  margin-bottom: 0%;
}
.section-a-props {
  width: 99vw;
  margin: 0px;
  padding: 0px;
  min-height: 916px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10%;
  margin-bottom: 0%;
  position: relative;
  top:14rem;
}