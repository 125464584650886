#section1 {
  height: 916px;
  background: url(../../assets/homeb.png);
  background-size: cover;
  background-position: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}


.main-txt {
  color: #fff;
  text-align: center;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  font-family: Poppins;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.Homepargraph {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  width: 58.5rem;
  margin-top: 0rem;
}

.Discoverb {
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  width: 13.4375rem;
  height: 3.9375rem;
  flex-shrink: 0;
  border-radius: 2.84375rem;
  border: 1px solid #f9fdff;
  background-color: transparent;
  color: white;
  margin: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.Discoverb:hover {
  background-color: #ffffff;
  color: #000000;
}

