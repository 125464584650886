#section6 {
  height: 62.625rem;
  background: url(../../assets/DSIbackground.jpg);
  background-size: cover;
  background-position: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.Header6 {
  width: 33.25rem;
  height: 12.9375rem;
  flex-shrink: 0;
  color: #fff;
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  line-height: 160%; /* 4.8rem */
  letter-spacing: 0.03rem;
  position: relative;
  margin-right: 50%;
  top: 8rem;
}
